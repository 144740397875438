import React from 'react'
import './Testimonials.css'

// Import Swiper React components
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    avatar:"" ,
    name: 'Morris Simons',
    role: 'CEO Blocket',
    review: 'I am working on the Atari project from the Intro to RL video. I see that the versions of stable_baselines3, gym and many other libraries has changed as of now.Thank you.'
  },
  {
    avatar: "",
    name: 'Morris Simons',
    role: 'CEO Blocket',
    review: 'I am working on the Atari project from the Intro to RL video. I see that the versions of stable_baselines3, gym and many other libraries has changed as of now.Thank you.'
  },
  {
    avatar: "",
    name: 'Morris Simons',
    role: 'CEO Blocket',
    review: 'I am working on the Atari project from the Intro to RL video. I see that the versions of stable_baselines3, gym and many other libraries has changed as of now.Thank you.'
  },
  {
    avatar: "",
    name: 'Morris Simons',
    role: 'CEO Blocket',
    review: 'I am working on the Atari project from the Intro to RL video. I see that the versions of stable_baselines3, gym and many other libraries has changed as of now.Thank you.'

  }
]


function Testimonials() {
  return (
    <section id="testimonials">
      <h5>Reviews from clients</h5>
      <h2>Testimonials</h2>
      <h5>*Placeholder</h5>

      <Swiper className="container testimonials__container"
          modules={[Pagination]}
          spaceBetween={40}
          slidesPerView={1}
          pagination={{ clickable: true }}>
        {
          data.map(({avatar,name,role,review}, index) => {
            return(
            <SwiperSlide key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar} alt={name} />
              </div> 
              <h5 className={name}>Morris Simons</h5>
              <h6 className={role}>CEO Blocket</h6>
              <small className={review}>
                I am working on the Atari project from the Intro to RL video. I see that the versions of stable_baselines3, gym and many other libraries has changed as of now.Thank you.
              </small>
          </SwiperSlide>
            )
        })
      }
      </Swiper>
    </section>
  )
}

export default Testimonials