import React from 'react'
import './About.css'
import ME from '../../assets/me-about.jpg'
import about1 from '../../assets/bokaforarporvlogo4.png'
import about2 from '../../assets/about2.webp'
import about3 from '../../assets/about3.png'
import { FaDiscord } from 'react-icons/fa'

const About = () => {
  return (
    <section id="about">
      <div className="container"></div>
      <h2>About Me</h2>

      <div className="about__socials">
        <a href="https://github.com/MorrisSimons" target="_blank" rel="noopener noreferrer">GitHub</a>
        <a href="https://x.com/MorrisSimons1" target="_blank" rel="noopener noreferrer">Twitter</a>
        <a href="https://linkedin.com/in/morris-simons-a8b519120" target="_blank" rel="noopener noreferrer">LinkedIn</a>
      </div>
      <div className="container about__container">

        <div className="about__content">


          <div className='about__me__container'>

            <div className="about__me-image">
              <img src={ME} alt="AboutMeImage" />
            </div>
            <div>
              <h4>MEng - AI and Machine Learning Student</h4>
              <p>

                I founded and built, together with one partner, Sweden's 3rd best e-commerce store according to Trustpilot out of 219 competing e-commerce stores on Trustpilot in only 6 months, with thousands of happy customers.
                <br></br>
                <br></br>
                Other projects I have built include Bokaförarprov.se and Citas-rapidas in Mexico, with over 300 bookings at the moment. I have also worked on various AI projects.
              </p>
              <a href="https://discord.com/users/183295119426977792" target="_blank" rel="noopener noreferrer" className="btn btn-primary talk-cta">
                <FaDiscord style={{ fontSize: '1.5em', marginRight: '8px', verticalAlign: 'middle' }} /> 
                Let's Talk on discord @morrissimons
              </a>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default About
